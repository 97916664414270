import { SECONDS_IN_HOUR } from '@lyra/core/constants/time'
import { createPrivateKey } from 'crypto'
import * as jose from 'jose'

const ALCHEMY_KEY_ID = process.env.ALCHEMY_KEY_ID!
const ALCHEMY_JWT_PRIVATE_KEY = process.env.ALCHEMY_JWT_PRIVATE_KEY!

const ALCHEMY_JW_DURATION = SECONDS_IN_HOUR // 1 hour

export const generateRpcTokenSERVER = async () => {
  // Convert PEM to crypto key
  const cryptoKey = createPrivateKey(ALCHEMY_JWT_PRIVATE_KEY)

  // Convert to JWK format
  const jwk = await jose.exportJWK(cryptoKey)

  // Create private key instance from JWK
  const privateKey = await jose.importJWK(jwk, 'RS256')

  // Sign an empty payload
  const token = await new jose.SignJWT({})
    .setProtectedHeader({
      alg: 'RS256',
      kid: ALCHEMY_KEY_ID,
    })
    .setExpirationTime(`${ALCHEMY_JW_DURATION}s`)
    .sign(privateKey)

  return { token, maxAgeSecs: ALCHEMY_JW_DURATION }
}
