import { PrivateGetAccountResultSchema } from '@lyra/core/api/types/private.get_account'
import { JWTPayload } from 'jose'
import { Hash, Hex, PrivateKeyAccount } from 'viem'
import { Address } from 'viem'

import { User } from './user'

export const ORDERBOOK_SECRET = process.env.ORDERBOOK_SECRET!
export const ORDERBOOK_SESSION_KEY_SECRET = process.env.ORDERBOOK_SESSION_KEY_SECRET!

export const MASTER_INVITE_CODE = process.env.NEXT_PUBLIC_MASTER_INVITE_CODE!

export class KytError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export type WalletSessionClaims = {
  address: Address
  ownerAddress: Address
  mock?: boolean
}

export type WalletSession = JWTPayload & WalletSessionClaims

export type TransactionDisabledReason =
  | 'kyt'
  | 'kyt-failed'
  | 'geoblocked'
  | 'ofac-restricted'
  | 'terms'
  | 'invalid-wallet'
  | 'eoa-reconnect'

export type Auth =
  | {
      isAuthenticated: true
      user: User
      account: PrivateGetAccountResultSchema
      accountDisabledReason: TransactionDisabledReason | null
      mock?: boolean
    }
  | {
      isAuthenticated: false
      user?: undefined
      account?: undefined
      accountDisabledReason?: undefined
      mock?: undefined
    }

export const NO_AUTH: Auth = {
  isAuthenticated: false,
}

export type LinkableSocial = 'discord' | 'twitter'

export type RegisterSessionKeyParams =
  | {
      hasRegisteredKeys: true
      authKey: PrivateKeyAccount
      authTimestamp: string
      authSignature: Hash
      sessionPrivateKey: Hex
      sessionKeySecret: CryptoKey
      sessionKey: PrivateKeyAccount
    }
  | {
      hasRegisteredKeys: false
    }
