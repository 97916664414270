import NewTestnetAddresses from './prod_lyra-testnet_addresses.json'
import OldTestnetAddresses from './prod_lyra-testnet-old_addresses.json'
import {
  SocketDepositContractAddresses,
  SocketWithdrawContractAddresses,
  TESTNET_CHAIN_ID,
} from './shared'

// Alternatively could explicitly list the chainIds to avoid casting as never/SocketDepositContractAddresses

const chainIds = Object.keys(NewTestnetAddresses).concat(...Object.keys(OldTestnetAddresses))

const depositContractAddresses: SocketDepositContractAddresses = {}

for (const chainId of chainIds) {
  const newAddrs = (NewTestnetAddresses as any)[chainId] ?? {}
  const oldAddrs = (OldTestnetAddresses as any)[chainId] ?? {}

  if (Object.keys(oldAddrs).some((tokenId) => Object.keys(newAddrs).includes(tokenId))) {
    throw new Error(`Token IDs must be unique across old and new addresses for chainId ${chainId}`)
  }

  for (const tokenId of Object.keys(oldAddrs)) {
    oldAddrs[tokenId].isNewBridge = false
  }
  for (const tokenId of Object.keys(newAddrs)) {
    newAddrs[tokenId].isNewBridge = true
  }
  depositContractAddresses[chainId] = {
    ...oldAddrs,
    ...newAddrs,
  }
}

const withdrawContractAddresses: SocketWithdrawContractAddresses = NewTestnetAddresses[
  TESTNET_CHAIN_ID
] as any

for (const tokenId of Object.keys(NewTestnetAddresses[TESTNET_CHAIN_ID])) {
  const addrs = (NewTestnetAddresses as any)[TESTNET_CHAIN_ID][tokenId]
  withdrawContractAddresses[tokenId] = {
    ...addrs,
    isNewBridge: true,
  }
}

for (const tokenId of Object.keys(OldTestnetAddresses[TESTNET_CHAIN_ID])) {
  const addrs = (OldTestnetAddresses as any)[TESTNET_CHAIN_ID][tokenId]
  withdrawContractAddresses[tokenId] = {
    ...addrs,
    isNewBridge: false,
  }
}

export const TESTNET_DEPOSIT_CONTRACT_ADDRESSES = depositContractAddresses
export const TESTNET_WITHDRAW_CONTRACT_ADDRESSES = withdrawContractAddresses
