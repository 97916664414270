import {
  OrderResponseSchema,
  PrivateOrderParamsSchema,
  TriggerPrice1,
} from '@lyra/core/api/types/private.order'
import { PrivateOrderQuoteResultSchema } from '@lyra/core/api/types/private.order_quote'
import {
  PublicOrderQuoteParamsSchema,
  PublicOrderQuoteResultSchema,
} from '@lyra/core/api/types/public.order_quote'

import { InstrumentType } from './instruments'

export enum TimeInForce {
  GoodTilCancelled = 'gtc',
  PostOnly = 'post_only',
  FillOrKill = 'fok',
  IoC = 'ioc',
}

export enum OrderType {
  Limit = 'limit',
  Market = 'market',
}

export enum TriggerPriceType {
  Mark = 'mark',
  Index = 'index',
}

export enum TriggerType {
  StopLoss = 'stoploss',
  TakeProfit = 'takeprofit',
}

export type OrderParams = PrivateOrderParamsSchema

export type Order = OrderResponseSchema

export type OpenOrder = Order & { order_status: 'open' | 'untriggered' }
export type ClosedOrder = Order & { order_status: 'filled' | 'cancelled' | 'expired' }

export type Orders = OrderResponseSchema[]

export type OrderParamsWithoutSignature = Omit<
  PrivateOrderParamsSchema,
  'signer' | 'signature' | 'nonce'
>

export type OrderQuoteParams = Omit<
  PublicOrderQuoteParamsSchema,
  | 'signer'
  | 'signature'
  | 'nonce'
  | 'signature_expiry_sec'
  | 'subaccount_id'
  | 'max_fee'
  | 'order_type'
  | 'time_in_force'
> & {
  // make fields required
  order_type: OrderType
  time_in_force: TimeInForce
}

export type SetOrderQuoteParams = (
  value: OrderQuoteParams | ((old: OrderQuoteParams | null) => OrderQuoteParams | null) | null
) => any

export type SetOrderQuoteParamsForInstrument = (
  value: OrderQuoteParams | ((old: OrderQuoteParams) => OrderQuoteParams | null) | null
) => any

export type Quote = PrivateOrderQuoteResultSchema
export type OrderQuote = PublicOrderQuoteResultSchema

export type TriggerPrice = TriggerPrice1

export const PERPS_LEVERAGE_STEP_SIZE = 0.01
export const DEFAULT_ORDER_EXPIRY_DAYS = 28
export const MAX_ORDER_EXPIRY_DAYS = 89

export const MARK_PRICE_FEE_RATE_CAP = 0.125

export const TPSL_BUY_MULTIPLIER = 1.02
export const TPSL_SELL_MULTIPLIER = 0.98

// TODO: @earthtojake get these from the API
export const DEFAULT_FEES: Record<InstrumentType, { taker: number; maker: number }> = {
  [InstrumentType.Options]: {
    taker: 0.0003,
    maker: 0.0001,
  },
  [InstrumentType.Perps]: {
    taker: 0.0003,
    maker: 0.0001,
  },
  [InstrumentType.Spot]: {
    taker: 0.0015,
    maker: 0.0015,
  },
}
