import { DefiLlamaBlockResponse, SECONDS_IN_DAY } from '@lyra/core/constants/time'

import { DepositNetwork } from '../constants/chains'
import { DEFILLAMA_API_URL } from '../constants/urls'

export const getUtcMs = (date: Date): number => {
  return date.getTime()
}

export const getUtcNowMs = (): number => getUtcMs(new Date())

export const getUtcSecs = (date: Date): number => {
  const expiryUtcMs = getUtcMs(date)
  return Math.floor(expiryUtcMs / 1000)
}

export const getUtcNowSecs = (): number => getUtcSecs(new Date())

export const getUtcDateFromSecs = (epochSecs: number) => {
  return new Date(epochSecs * 1000)
}

export const getUtcDateFromMs = (epochMs: number) => {
  return new Date(epochMs)
}

export const isValidUtcEpochSecs = (timestamp: number): boolean => {
  // Check if the input is a number and non-negative
  if (timestamp < 0) {
    return false
  }

  // Convert seconds to milliseconds for JavaScript Date
  const date = new Date(timestamp * 1000)

  // Check if the date is valid
  return !isNaN(date.getTime())
}

export const daysBetweenDatesRoundedUp = (start: Date, end: Date) => {
  const diffInMilliseconds = Math.max(0, end.getTime() - start.getTime())
  return Math.ceil(diffInMilliseconds / (SECONDS_IN_DAY * 1000))
}

export const roundToNearest500Seconds = (timestamp: number): number => {
  return Math.round(timestamp / 500) * 500
}

export const getStartOfMonthTimestamp = () => {
  const now = new Date()
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0)
  return startOfMonth.getTime()
}

export const getEndOfMonthTimestamp = () => {
  const now = new Date()
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999)
  return endOfMonth.getTime()
}

export function getStartOfDay(date: number | Date): number {
  const createDate = new Date(date)

  const utcStartDate = new Date(
    Date.UTC(
      createDate.getUTCFullYear(),
      createDate.getUTCMonth(),
      createDate.getUTCDate(),
      0,
      0,
      0 // 00:00:00 UTC
    )
  )

  // Return the start of day in MS
  return utcStartDate.getTime()
}

export function getStartOfWeek(date: number | Date): number {
  const createDate = new Date(date)
  const day = createDate.getUTCDay()
  const diff = createDate.getUTCDate() - day + (day === 0 ? -6 : 1)
  const startOfWeek = new Date(
    Date.UTC(createDate.getUTCFullYear(), createDate.getUTCMonth(), diff)
  )
  return startOfWeek.getTime()
}

export function getStartOfMonth(date: number | Date): number {
  const createDate = new Date(date)
  const startOfMonth = new Date(Date.UTC(createDate.getUTCFullYear(), createDate.getUTCMonth(), 1))
  return startOfMonth.getTime()
}

export const roundTimestampToLastInterval = (timestampMs: number, intervalMs: number) => {
  const roundedTimestamp = Math.floor(timestampMs / intervalMs) * intervalMs
  return roundedTimestamp
}

export const fetchBlockForTimestamp = async (
  network: DepositNetwork,
  endTimestampMs: number
): Promise<DefiLlamaBlockResponse> => {
  const res = await fetch(
    `${DEFILLAMA_API_URL}/block/${network}/${Math.floor(endTimestampMs / 1000)}`
  )
  return await res.json()
}

export const getStartOfPastDay = (daysAgo: number): number => {
  const pastDate = new Date(getUtcNowMs() - daysAgo * SECONDS_IN_DAY * 1000)
  return getStartOfDay(pastDate)
}
