const getBaseUrl = () => {
  if (process.env.NEXT_PUBLIC_OVERRIDE_URL) {
    return process.env.NEXT_PUBLIC_OVERRIDE_URL
  }
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:3001'
    case 'production':
      switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
        case 'production':
          return `https://${process.env.NEXT_PUBLIC_URL}`
        case 'preview':
          return `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`
        case 'development':
          return `http://localhost:${process.env.PORT}`
        default:
          throw new Error(
            'Unsupported NEXT_PUBLIC_VERCEL_ENV: ' + process.env.NEXT_PUBLIC_VERCEL_ENV
          )
      }
    default:
      throw new Error('Unsupported NODE_ENV: ' + process.env.NODE_ENV)
  }
}

export const baseUrl = getBaseUrl()

// product links
export const BASE_DOMAIN = 'derive.xyz'
export const LEGACY_BASE_DOMAIN = 'lyra.finance'
export const MAINNET_APP_URL = 'https://www.derive.xyz'
export const TESTNET_APP_URL = 'https://testnet.derive.xyz'
export const MAINNET_RPC_URL = 'https://rpc.derive.xyz'

// social links
export const DISCORD_URL = 'https://discord.gg/Derive'
export const TWITTER_URL = 'https://www.x.com/derivexyz'
export const WARPCAST_URL = 'https://warpcast.com/lyra.eth'
export const GITHUB_URL = 'https://github.com/derivexyz'
export const YOUTUBE_URL = 'https://www.youtube.com/@derivexyz'
export const TELEGRAM_URL = 'https://t.me/Derive_Announcements'

// secondary links
export const STATUS_URL = 'https://status.derive.xyz'
export const WHITEPAPER_URL = `${baseUrl}/files/whitepaper.pdf`
export const GOVERNANCE_PORTAL_URL = 'https://gov.derive.xyz'
export const GOVERNANCE_FORUMS_URL = 'https://forums.derive.xyz'
export const SNAPSHOT_URL = 'https://snapshot.org/#/lyra.eth'
export const CAREERS_URL = 'https://www.linkedin.com/company/lyra-foundation/jobs/'
export const GHOST_API_URl = 'https://lyra-2.ghost.io'
export const MAINNET_BLOCK_EXPLORER_URL = 'https://explorer.derive.xyz'
export const TESTNET_RPC_URL = 'https://rpc-prod-testnet-0eakp60405.t.conduit.xyz'
export const TESTNET_BLOCK_EXPLORER_URL = 'https://explorer-prod-testnet-0eakp60405.t.conduit.xyz'
export const DISCORD_SUPPORT_URL = 'https://discord.gg/Nh7TGyUr'

// blog links
export const BLOG_URL = 'https://blog.derive.xyz'
export const BLOG_DERIVE_REBRAND_URL = `${BLOG_URL}/derive`

// help links
export const HELP_URL = 'https://help.derive.xyz'
export const HELP_BRIDGE_URL = `${HELP_URL}/en/articles/9086191-what-bridge-does-lyra-use`
export const HELP_ENABLE_SPENDING_URL = `${HELP_URL}/en/articles/9113952-why-do-i-need-to-enable-spending`
export const HELP_ENABLE_DERIVE_URL = `${HELP_URL}/en/articles/9304496-why-do-i-need-to-enable-lyra`
export const HELP_EXPORT_EMBEDDED_WALLET_PRIVATE_KEY_URL = `${HELP_URL}/en/articles/9267434-exporting-lyra-wallet-private-key`
export const HELP_COVERED_CALL_RISKS_URL = `${HELP_URL}/en/articles/9351633-covered-call-token-risks`
export const HELP_COVERED_CALL_STRATEGY_URL = `${HELP_URL}/en/articles/9351620-covered-call-strategy`
export const HELP_COVERED_CALL_SPREAD_RISKS_URL = `${HELP_URL}/en/articles/9769827-covered-call-spread-risks`
export const HELP_COVERED_CALL_SPREAD_STRATEGY_URL = `${HELP_URL}/en/articles/9767136-covered-call-spread-strategy`
export const HELP_BULL_STRATEGY_URL = `${HELP_URL}/en/articles/9555717-bull-strategy`
export const HELP_BULL_RISKS_URL = `${HELP_URL}/en/articles/9555727-bull-risks`
export const HELP_LENDING_URL = `${HELP_URL}/en/articles/9085377-borrowing-lending`
export const HELP_FEES_URL = `${HELP_URL}/en/articles/8691534-what-are-the-fees`
export const HELP_DRV_URL = `${HELP_URL}/en/articles/10381892-drv#h_adbabfa26d`

// Covered Put Spread
export const HELP_COVERED_PUT_SPREAD_STRATEGY_URL = `${HELP_URL}/en/articles/9823406-covered-put-spread-strategy`
export const HELP_COVERED_PUT_SPREAD_RISKS_URL = `${HELP_URL}/en/articles/9823656-covered-put-spread-risks`

// docs links
export const DOCS_URL = 'https://docs.derive.xyz'
export const DOCS_ONBOARDING_GUIDE_URL = `${DOCS_URL}/docs`
export const DOCS_API_REFERENCE_URL = `${DOCS_URL}/reference`
export const DOCS_ORACLES_URL = `${DOCS_URL}/docs/oracles-1`
export const DOCS_FEES_URL = `${DOCS_URL}/reference/fees-1`
export const DOCS_SESSION_KEYS_URL = `${DOCS_URL}/reference/session-keys`
export const DOCS_FEE_TIERS_PROGRAM_URL = `${DOCS_URL}/docs/retail-trading-rewards-program`

// external links
export const ETHERFI_URL =
  'https://app.ether.fi/eeth?address=0x246d38588b16Dd877c558b245e6D5a711C649fCF'
export const SWELL_URL =
  'https://app.swellnetwork.io/restake?ref=0x246d38588b16Dd877c558b245e6D5a711C649fCF'
export const KELP_URL = 'https://kelpdao.xyz/restake/'
export const LOMBARD_URL = 'https://www.lombard.finance'
export const BYBIT_WEB3_WALLET_CAMPAIGN_URL =
  'https://www.bybit.com/en/web3/airdrop/activity?activityId=301&activityTag=3'

export const DEFILLAMA_API_URL = 'https://coins.llama.fi'

export const AERODROME_SWAP_URL =
  'https://aerodrome.finance/swap?from=eth&to=0x9d0e8f5b25384c7310cb8c6ae32c8fbeb645d083'

export const COINGECKO_DRV_URL = 'https://www.coingecko.com/en/coins/derive'
export const COINMARKETCAP_DRV_URL = 'https://coinmarketcap.com/currencies/derive'
export const GATE_DRV_URL = 'https://www.gate.io/price/derive-drv'
export const KRAKEN_DRV_URL = 'https://www.kraken.com/prices/derive'
