import { Address } from 'viem'

import { HistoryTab } from '../constants/history'
import { MarketId } from './markets'
import { OrderQueryParam } from './query'
import { CollateralId } from './tokens'

export enum PageId {
  CreateWallet = 'CreateWallet',
  Landing = 'Landing',
  TermsOfUse = 'TermsOfUse',
  AirdropTerms = 'AirdropTerms',
  PrivacyPolicy = 'PrivacyPolicy',
  Options = 'Options',
  Perps = 'Perps',
  Spot = 'Spot',
  Portfolio = 'Portfolio',
  Home = 'Home',
  History = 'History',
  Balances = 'Balances',
  Developers = 'Developers',
  Alpha = 'alpha',
  Stats = 'Stats',
  Leaderboard = 'Leaderboard',
  Earn = 'Earn',
  Vault = 'Vault',
  Referrals = 'Referrals',
  Borrow = 'Borrow',
  Collateral = 'Collateral',
  Rfq = 'Rfq',
  Partners = 'Partners',
  Positions = 'Positions',
  Amberdata = 'Amberdata',
  User = 'User',
  Trades = 'Trades',
  Deposits = 'Deposits',
  DRV = 'DRV',
  Airdrop = 'Airdrop',
  Claim = 'Claim',
  Delegate = 'Delegate',
  DelegateMigration = 'DelegateMigration',
  Migration = 'Migration',
  Rewards = 'Rewards',
  TradingFees = 'TradingFees',
}

export type PageArgsMap = {
  [PageId.Landing]: undefined
  [PageId.TermsOfUse]: undefined
  [PageId.PrivacyPolicy]: undefined
  [PageId.Options]:
    | {
        marketId?: MarketId
        order?: OrderQueryParam
        expiry?: Date
      }
    | undefined
  [PageId.Perps]:
    | {
        marketId?: MarketId
        order?: OrderQueryParam
      }
    | undefined
  [PageId.Spot]:
    | {
        marketId?: CollateralId
        order?: OrderQueryParam
      }
    | undefined
  [PageId.Portfolio]: undefined
  [PageId.Home]: undefined
  [PageId.History]: {
    tab?: HistoryTab
  }
  [PageId.CreateWallet]: undefined
  [PageId.Alpha]: undefined
  [PageId.Developers]: undefined
  [PageId.Stats]: undefined
  [PageId.DRV]: {
    code?: string
  }
  [PageId.Balances]: undefined
  [PageId.Earn]: undefined
  [PageId.Vault]: {
    id: string
  }
  [PageId.Referrals]: undefined
  [PageId.Borrow]: undefined
  [PageId.Collateral]: {
    collateralId: CollateralId
  }
  [PageId.Rfq]: undefined
  [PageId.Partners]: undefined
  [PageId.Positions]: undefined
  [PageId.Amberdata]: undefined
  [PageId.Leaderboard]: undefined
  [PageId.User]: {
    address: Address
  }
  [PageId.Trades]: undefined
  [PageId.Deposits]: undefined
  [PageId.Claim]: undefined
  [PageId.Delegate]: undefined
  [PageId.Airdrop]: undefined
  [PageId.Migration]: undefined
  [PageId.DelegateMigration]: {
    amount?: string
  }
  [PageId.AirdropTerms]: undefined
  [PageId.Rewards]: undefined
  [PageId.TradingFees]: undefined
}

export type Pathname = `/${string}`

type PageArgsWithPageMap<M extends Record<string, any>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        page: Key
      }
    : {
        page: Key
      } & M[Key]
}
export type PagePathArgs<T extends PageId> = PageArgsWithPageMap<PageArgsMap>[T]
