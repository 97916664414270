import { EndpointId, Network } from '@layerzerolabs/lz-definitions'
import { TxStatus } from '@lyra/core/api/types/channel.subaccount_id.trades'
import { PrivateDepositParamsSchema } from '@lyra/core/api/types/private.deposit'
import { MAX_INT } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY, SECONDS_IN_THREE_DAYS } from '@lyra/core/constants/time'
import { Address, Hash } from 'viem'

import { DepositNetwork } from './chains'
import { isMainnet } from './env'
import {
  MAINNET_DEPOSIT_CONTRACT_ADDRESSES,
  MAINNET_WITHDRAW_CONTRACT_ADDRESSES,
} from './socket/bridgeAddresses'
import {
  TESTNET_DEPOSIT_CONTRACT_ADDRESSES,
  TESTNET_WITHDRAW_CONTRACT_ADDRESSES,
} from './socket/bridgeAddresses.testnet'
import { DepositTokenId } from './tokens'
import { YieldStrategyId } from './yield'

export type SocketMessageStatus =
  | 'LYRA_CONFIRMING'
  | 'RECEIVED'
  | 'SEALED'
  | 'PROPOSED'
  | 'ATTESTED'
  | 'VERIFIED'
  | 'EXECUTING'
  | 'EXECUTION_SUCCESS'
  // Reverting
  | 'INBOUND_REVERTING'
  // Failed
  | 'EXECUTION_FAILURE'
  // Merge deprecated Gelato statuses
  | 'ExecReverted'
  | 'Cancelled'

type LayerZeroMessageStatus =
  | 'LYRA_CONFIRMING'
  | 'CONFIRMING'
  // The message arrived at the destination, but reverted or ran out of gas during execution and needs to be retried.
  | 'PAYLOAD_STORED'
  | 'INFLIGHT'
  | 'DELIVERED'
  // A previous message nonce has a stored payload, halting the current transaction.
  | 'BLOCKED'
  // The transaction encountered an error and did not complete.
  | 'FAILED'

// Gelato TaskState for history
enum TaskState {
  CheckPending = 'CheckPending',
  ExecPending = 'ExecPending',
  WaitingForConfirmation = 'WaitingForConfirmation',
  ExecSuccess = 'ExecSuccess',
  ExecReverted = 'ExecReverted',
  Cancelled = 'Cancelled',
}

// Gelato task status
export type RelayerStatus = TaskState

export type MessageStatus = SocketMessageStatus

// Union of orderbook status and:
//   queued - bridge has not completed yet
//   rpc-error - a known orderbook RPC error returned by API call
//   error - some other error returned by API call
//   error-unknown - an error with the fetch
export type SubaccountDepositStatus = TxStatus | 'queued' | 'rpc-error' | 'error' | 'error-unknown'

export const SOCKET_MESSAGE_STATUSES = [
  'LYRA_CONFIRMING',
  'RECEIVED',
  'SEALED',
  'PROPOSED',
  'ATTESTED',
  'VERIFIED',
  'EXECUTING',
  'EXECUTION_SUCCESS',
  // Reverting
  'INBOUND_REVERTING',
  // Failed
  'EXECUTION_FAILURE',
]

export const BRIDGE_FAILED_STATUSES: MessageStatus[] = [
  'EXECUTION_FAILURE',
  TaskState.Cancelled,
  TaskState.ExecReverted,
]

export const SOCKET_MESSAGE_COMPLETE_STATUSES: MessageStatus[] = [
  'EXECUTION_FAILURE',
  'EXECUTION_SUCCESS',
]

export const SOCKET_PENDING_STATUSES: MessageStatus[] = [
  'LYRA_CONFIRMING',
  'RECEIVED',
  'SEALED',
  'PROPOSED',
  'ATTESTED',
  'VERIFIED',
  'EXECUTING',
  'INBOUND_REVERTING',
]

export const LAYER_ZERO_COMPLETE_STATUSES: LayerZeroMessageStatus[] = [
  'DELIVERED',
  'FAILED',
  'BLOCKED',
]

// Statuses that indicate the transaction does not require anymore updates, i.e.
// Gelato task or Socket message has failed
// OR Socket message is complete
export const TX_COMPLETE_STATUSES = [...SOCKET_MESSAGE_COMPLETE_STATUSES]

// Any status except "queued"
export const SUBACCOUNT_COMPLETE_STATUS: SubaccountDepositStatus[] = [
  'requested',
  'settled',
  'reverted',
  'timeout',
  'rpc-error',
  'error',
  'error-unknown',
]
export const BRIDGE_GAS_SUBMITTED_STATUSES: MessageStatus[] = [
  'EXECUTION_SUCCESS',
  'INBOUND_REVERTING',
  TaskState.ExecReverted,
]

type InboundStatus = 'NOT_FOUND' | 'NOT_TRIED' | 'REVERTING' | 'EXECUTING' | 'SUCCESS'

/**
 * Derived client-side status
 * Reverted - Socket transaction reverted or subaccount deposit failed/reverted (fees have been paid)
 * Cancelled - Gelato task has failed, no fees paid
 */
export type BridgeTransactionStatus = 'pending' | 'completed' | 'reverted' | 'cancelled'

export type SocketStatusResponse = {
  status: InboundStatus
  result: {
    status: SocketMessageStatus
    messageId: string
    packetId: string
    from: {
      srcChainSlug: string
      srcPlug: string
    }
    to: {
      dstChainSlug: string
      destPlug: string
    }
    payload: string
    outboundTx: string
    packedMessage: string
    inboundTx: string
    executionDetails: {
      inboundStatus: string // Replace with the actual type for inbound status
      isExecuted: boolean
      executor: string
      executionTxHash: string
    }
  }[]
}

export type SocketMessageIdStatusResponse = {
  status: InboundStatus
  result: {
    status: SocketMessageStatus
    messageId: string
    packetId: string
    from: {
      srcChainSlug: string
      srcPlug: string
    }
    to: {
      dstChainSlug: string
      destPlug: string
    }
    payload: string
    outboundTx: string
    packedMessage: string
    inboundTx: string
    executionDetails: {
      inboundStatus: string // Replace with the actual type for inbound status
      isExecuted: boolean
      executor: string
      executionTxHash: string
    }
  }
}

export type LayerZeroStatusResponse = {
  data: {
    pathway: {
      srcEid: EndpointId
      dstEid: EndpointId
      sender: {
        address: Address
        chain: Network
      }
      receiver: {
        address: Address
        chain: Network
      }
      id: string
      nonce: number
    }
    source: {
      status: LayerZeroMessageStatus
      tx: {
        txHash: Hash
        blockHash: string
        blockNumber: string
        blockTimestamp: number
        from: string
        payload: string
        readinessTimestamp: number
        options: {
          lzReceive: {
            gas: string
            value: string
          }
          ordered: boolean
        }
      }
    }
    destination: {
      nativeDrop: {
        status: string
      }
      lzCompose: {
        status: string
      }
      tx: {
        txHash: Hash
        blockHash: string
        blockNumber: number
        blockTimestamp: number
      }
      status: string
    }
    status: {
      name: LayerZeroMessageStatus
      message: string
    }
    created: string
    updated: string
  }[]
}

export type BridgeTransactionType =
  | 'deposit'
  | 'withdraw'
  | 'predeposit-yield'
  | 'prewithdraw-yield'
  | 'deposit-yield'
  | 'withdraw-yield'

export const yieldTransactionTypes: BridgeTransactionType[] = [
  'predeposit-yield',
  'prewithdraw-yield',
  'deposit-yield',
  'withdraw-yield',
]

export type DepositQuoteGasOptions = {
  srcChainGasEstimate?: number
  maxFeePerGas?: number
  maxPriorityFeePerGas?: number
}

export type SubaccountDepositFields = {
  depositParams: PrivateDepositParamsSchema
  status: SubaccountDepositStatus
  transactionId?: string | null
}

export type BridgeTransactionDEPRECATED = {
  sender: Address
  receiver: Address
  timestamp: number
  token: {
    address: Address
    symbol: string
  }
  amount: string
} & (
  | {
      type: 'deposit'
      usdValue: number
      bridgeToLyra: BridgeToLyraStatusDEPRECATED
    }
  | {
      type: 'withdraw'
      bridgeFromLyra: BridgeFromLyraStatusDEPRECATED
      usdValue: number
    }
  // Yield Bridge
  | {
      type: 'predeposit-yield' | 'prewithdraw-yield' | 'deposit-yield' | 'withdraw-yield'
      bridgeFromLyra: BridgeFromLyraStatusDEPRECATED
      bridgeToLyra: BridgeToLyraStatusDEPRECATED
      strategy: YieldStrategyId
    }
)

export type BridgeToLyraStatusDEPRECATED = {
  status: SocketMessageStatus
  srcChainId: number
  srcTxHash: `0x${string}` | null
  lyraTxHash: `0x${string}` | null
  gelato?: {
    status: RelayerStatus
    taskId: string
  } | null
  // subaccountDeposit is DEPRECATED, keeping the field for old db entries
  subaccountDeposit?: {
    params: PrivateDepositParamsSchema
    status: SubaccountDepositStatus
    error?: any
    transactionId?: string
  } | null
  isNative?: boolean
  isSponsored?: boolean
}

export type BridgeFromLyraStatusDEPRECATED = {
  status: SocketMessageStatus
  dstChainId: number
  dstTxHash: `0x${string}` | null
  lyraTxHash: `0x${string}` | null
  lyraOutboundMessageId?: Hash
}

export type BridgeTxStatus = {
  srcChainId: number
  dstChainId: number
  srcTxHash: Hash | null
  dstTxHash: Hash | null
}

export type SocketBridgeStatus = {
  bridgeType: 'socket'
  status: SocketMessageStatus
  outboundMessageId?: Hash
}

export type LayerZeroBridgeStatus = {
  bridgeType: 'layer-zero'
  status: LayerZeroMessageStatus
}

export const bridgeTypes = ['socket', 'layer-zero'] as const
export type BridgeType = (typeof bridgeTypes)[number]

type BridgeDepositWithdrawFields = BridgeTxStatus &
  (
    | {
        type: 'deposit'
        usdValue: number
      }
    | {
        type: 'withdraw'
        usdValue: number
      }
  )

export type BridgeTransaction = {
  sender: Address
  receiver: Address
  timestamp: number
  token: {
    address: Address
    symbol: string
  }
  amount: string
} & (
  | (SocketBridgeStatus & BridgeDepositWithdrawFields)
  | (LayerZeroBridgeStatus & BridgeDepositWithdrawFields)
  | {
      // Yield Bridge
      type: 'predeposit-yield' | 'prewithdraw-yield' | 'deposit-yield' | 'withdraw-yield'
      toStatus: SocketBridgeStatus & BridgeTxStatus
      fromStatus: SocketBridgeStatus & BridgeTxStatus
      strategy: YieldStrategyId
    }
)

export type BridgeToLyraStatusPublic = Omit<BridgeToLyraStatusDEPRECATED, 'subaccountDeposit'>

export type BridgeFromLyraStatusPublic = Omit<
  BridgeFromLyraStatusDEPRECATED,
  'lyraOutboundMessageId'
>

export type BridgeTransactionPublic = {
  sender: Address
  receiver: Address
  timestamp: number
  token: {
    address: Address
    symbol: string
  }
  amount: string
} & (
  | {
      type: 'deposit'
      bridgeToLyra: BridgeToLyraStatusPublic
    }
  | {
      type: 'withdraw'
      bridgeFromLyra: BridgeFromLyraStatusPublic
    }
  // Yield Bridge
  | {
      type: 'predeposit-yield' | 'prewithdraw-yield' | 'deposit-yield' | 'withdraw-yield'
      bridgeFromLyra: BridgeFromLyraStatusPublic
      bridgeToLyra: BridgeToLyraStatusPublic
    }
)

export type SocketExchangeBridgeTransaction = BridgeTransaction & {
  bridgeType: 'socket'
  type: 'deposit' | 'withdraw'
}

export type LayerZeroExchangeBridgeTransaction = BridgeTransaction & {
  bridgeType: 'layer-zero'
  type: 'deposit' | 'withdraw'
}

export type ExchangeBridgeTransaction = BridgeTransaction & {
  type: 'deposit' | 'withdraw'
}

export type DepositTransaction = BridgeTransaction & {
  type: 'deposit'
}

export type WithdrawTransaction = BridgeTransaction & {
  type: 'withdraw'
}

export type BridgeOptions = {
  isMaxApproval: boolean
}

// Eligible if no deposits in 72 hrs
export const SPONSORED_DEPOSIT_TIME_THRESHOLD_MS = SECONDS_IN_THREE_DAYS * 1000
export const SPONSORED_DEPOSIT_INTERVAL_DAYS = Math.ceil(
  SPONSORED_DEPOSIT_TIME_THRESHOLD_MS / (SECONDS_IN_DAY * 1000)
)

/**
 * Max 5 sponsored deposits within 72 hours for OP/ARB
 * AND
 * Max 1 sponsored deposit within 72 hours for Ethereum
 */
export const MAXIMUM_L1_SPONSORED_DEPOSITS = 1
export const MAXIMUM_L2_SPONSORED_DEPOSITS = 5

// Gas limit for minting tokens on Lyra Chain
// Used for socket fee estimation
export const SOCKET_BRIDGE_L2_GAS_LIMIT = 100_000
export const SOCKET_BRIDGE_YIELD_DEPOSIT_GAS_LIMIT = 2_000_000

export const LAYER_ZERO_GAS_LIMIT = 100_000

// For Lyra Chain approvals, min allowance required before user deposit is flagged
export const MIN_DEPOSIT_ALLOWANCE = MAX_INT / BigInt(100_000)

export const COLLATERAL_USAGE_ROUNDING = 0.999 // 0.1%
// Dust amount to disregard when calculating withdrawable balance
export const COLLATERAL_DUST_AMOUNT = 1e-9

export type BridgeContractAddresses =
  | ({
      type: 'socket'
      // Address for fast bridge on source chain
      socketDepositFastConnector: Address
      // Address for vault on source chain
      socketVault: Address
      tokenAddress: Address
      // Uses new Socket bridge implementation
      isNewBridge: boolean
    } & ExternalBridgeContractAddressesConfig)
  | {
      type: 'layer-zero'
      layerZeroOftAddress: Address
    }

export type ExternalBridgeContractAddressesConfig = {
  nativeHelper: Address
  //    // Gelato sponsored deposit helper contract on source chain
  gelatoSponsoredHelper?: Address
  // Gelato self-paying deposit helper contract on source chain
  // Note: testnet does not support self-paying
  gelatoSelfPayingHelper?: Address
}

export type SocketBridgeContractAddresses = {
  vault: Address
  fastConnector: Address
}

const mainnetNativeHelperContractAddress: Record<
  DepositNetwork,
  ExternalBridgeContractAddressesConfig
> = {
  [DepositNetwork.Ethereum]: {
    nativeHelper: '0x18a0f3F937DD0FA150d152375aE5A4E941d1527b',
    gelatoSponsoredHelper: '0xf0372da389db728a3173a7b91c5cb4437a6319ea',
    gelatoSelfPayingHelper: '0x00efac83a3168568e258ab1ec85e85c10cbaf74e',
  },
  [DepositNetwork.Optimism]: {
    nativeHelper: '0xC65005131Cfdf06622b99E8E17f72Cf694b586cC',
  },
  [DepositNetwork.Arbitrum]: {
    nativeHelper: '0x076BB6117750e80AD570D98891B68da86C203A88',
    gelatoSelfPayingHelper: '0x00eFAc83a3168568e258ab1Ec85E85C10cBAf74E',
    gelatoSponsoredHelper: '0x6FEf1bb8Ade9A836663d4c15AFd5985Fb545004f',
  },
  [DepositNetwork.Base]: {
    nativeHelper: '0x9628BBa16DB41EA7fe1FD84f9CE53bC27c63f59b',
    gelatoSelfPayingHelper: '0xd8d46a044f62d97733707176D6791b0a73cd7f6C',
    gelatoSponsoredHelper: '0x8984c20DF1DdB9344554f01d36FE753037b4A108',
  },
}

const testnetNativeHelperContractAddress: Record<
  DepositNetwork,
  ExternalBridgeContractAddressesConfig | undefined
> = {
  [DepositNetwork.Ethereum]: {
    nativeHelper: '0x46e75b6983126896227a5717f2484efb04a0c151',
    gelatoSponsoredHelper: '0xf0372da389db728a3173a7b91c5cb4437a6319ea',
  },
  [DepositNetwork.Optimism]: {
    nativeHelper: '0x3E7DEc059a3692c184BF0D0AC3d9Af7570DF6A3c',
  },
  [DepositNetwork.Arbitrum]: {
    nativeHelper: '0x5708bDE1c5e49b62cfd46D07b5cd3c898930Ef23',
    gelatoSponsoredHelper: '0xE3436F0F982fbbAf88f28DACE9b36a85c97aECdE',
  },
  [DepositNetwork.Base]: undefined,
}

export const externalDepositContractAddresses = isMainnet
  ? mainnetNativeHelperContractAddress
  : testnetNativeHelperContractAddress

export type BridgeWithdrawContractAddresses = {
  // Address for controller on Lyra Chain
  // Note: this determines destination chain and destination token for withdraws
  // e.g. for USDC vs USDC.e
  socketController: Address
  // Address for fast bridge on Lyra chain
  socketWithdrawFastConnector: Address
  withdrawHelper: Address
  lyraTokenAddress: Address
} & BridgeWithdrawContractHookAddresses

export type BridgeWithdrawContractHookAddresses =
  | {
      isNewBridge: false
      shareHandlerWithdrawHook: undefined
    }
  | {
      isNewBridge: true
      shareHandlerWithdrawHook: Address
    }

const MAINNET_WITHDRAW_HELPER_ADDRESS = '0xea8E683D8C46ff05B871822a00461995F93df800'
const TESTNET_WITHDRAW_HELPER_ADDRESS = '0x2805b908a0f9ca58a2b3b7900341b4ebd0b994e9'

export const socketWithdrawHelperAddress = isMainnet
  ? MAINNET_WITHDRAW_HELPER_ADDRESS
  : TESTNET_WITHDRAW_HELPER_ADDRESS

export const SOCKET_TX_API_URL = 'https://prod.dlapi.socket.tech/messages-from-tx'

export const SOCKET_MESSAGE_API_URL = 'https://prod.dlapi.socket.tech/message'

const getSocketDepositAddresses = () => {
  return isMainnet ? MAINNET_DEPOSIT_CONTRACT_ADDRESSES : TESTNET_DEPOSIT_CONTRACT_ADDRESSES
}

const getSocketWithdrawAddresses = () => {
  return isMainnet ? MAINNET_WITHDRAW_CONTRACT_ADDRESSES : TESTNET_WITHDRAW_CONTRACT_ADDRESSES
}

export const socketDepositContractAddresses = getSocketDepositAddresses()
export const socketWithdrawContractAddresses = getSocketWithdrawAddresses()

export const supportedDrvBridgeNetworks = [
  DepositNetwork.Ethereum,
  DepositNetwork.Arbitrum,
  DepositNetwork.Base,
  DepositNetwork.Optimism,
]

export const LAYER_ZERO_SCAN_API_URL = isMainnet
  ? 'https://scan.layerzero-api.com/v1'
  : 'https://scan-testnet.layerzero-api.com/v1'

export const layerZeroWithdrawHelperAddress = isMainnet
  ? '0x9400cc156dad38a716047a67c897973A29A06710'
  : '0xAeb75d048f2757e97267418939DD2cc148861160'

export const layerZeroDepositTokenAddresses: Record<
  DepositNetwork,
  { [key in DepositTokenId]?: Address }
> = {
  [DepositNetwork.Ethereum]: {
    [DepositTokenId.DRV]: isMainnet
      ? '0xB1D1eae60EEA9525032a6DCb4c1CE336a1dE71BE'
      : '0xeCAAd20Fe81818E4Ce1EB35E463f681084ab2DD5',
  },
  [DepositNetwork.Arbitrum]: {
    [DepositTokenId.DRV]: isMainnet ? '0x77b7787a09818502305C95d68A2571F090abb135' : undefined,
  },
  [DepositNetwork.Optimism]: {
    [DepositTokenId.DRV]: isMainnet ? '0x33800De7E817A70A694F31476313A7c572BBa100' : undefined,
  },
  [DepositNetwork.Base]: {
    [DepositTokenId.DRV]: isMainnet ? '0x9d0E8f5b25384C7310CB8C6aE32C8fbeb645d083' : undefined,
  },
}

export const deriveEid = isMainnet ? EndpointId.LYRA_V2_MAINNET : EndpointId.LYRA_V2_TESTNET
