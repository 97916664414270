import {
  PublicGetAllCurrenciesParamsSchema,
  PublicGetAllCurrenciesResponseSchema,
} from '../api/types/public.get_all_currencies'
import tryRequest, { GetRequestOptions } from './tryRequest'

export default async function fetchAllCurrencies(
  options?: GetRequestOptions
): Promise<PublicGetAllCurrenciesResponseSchema> {
  return tryRequest<PublicGetAllCurrenciesParamsSchema, PublicGetAllCurrenciesResponseSchema>(
    '/public/get_all_currencies',
    {},
    {
      ...options,
      useGet: true,
    }
  )
}
