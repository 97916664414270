import { isTestnet } from './env'

export const MOCK_SESSION_COOKIE_NAME = 'derive-mock-session-did'
export const ALCHEMY_RPC_COOKIE_NAME = 'derive-alchemy-rpc-token'
export const ADMIN_COOKIE_NAME = 'derive-admin'
export const INVITE_CODE_COOKIE_NAME = isTestnet
  ? 'derive-testnet-invite-code'
  : 'derive-mainnet-invite-code'
export const WALLET_SESSION_TOKEN_COOKIE_NAME = 'derive.session-token'
export const WALLET_NONCE_COOKIE_NAME = 'derive.siwe-nonce'
export const WALLET_REFRESH_TOKEN_COOKIE_NAME = 'derive.refresh-token'
