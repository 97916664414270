import { Address } from 'viem'

import { DepositNetwork } from './chains'
import { isMainnet } from './env'
import { MarketId } from './markets'
import { CollateralId } from './tokens'

// NOTE: This is the default address we use for the manager contract. It is preferable to use a zero address according to the smart contract team.
export const NEW_MANAGER_ADDRESS = '0x0000000000000000000000000000000000000000'

export type LyraContractAddresses = {
  subaccounts: Address
  standardManager: Address
  deposit: Address
  withdraw: Address
  transfer: Address
  matching: Address
  trade: Address
  liquidate: Address
  rfq: Address
  portfolioManager: Partial<Record<MarketId, Address>>
  accountEntryPoint: Address
  accountFactory: Address
  paymaster: Address
  l1CrossDomainMessengerProxy: Address
  subaccountCreator: Address
  collateral: Record<CollateralId, Address | undefined>
  tsaShareHandler: Address
  // DRV migration claims contract
  migrationClaim: Address
  // Airdrop + staking rewards claims
  rewardsDistributor: Address
}

export const testnetLyraContractAddresses: LyraContractAddresses = {
  subaccounts: '0xb9ed1cc0c50bca7a391a6819e9cAb466f5501d73',
  standardManager: '0x28bE681F7bEa6f465cbcA1D25A2125fe7533391C',
  deposit: '0x43223Db33AdA0575D2E100829543f8B04A37a1ec',
  withdraw: '0xe850641C5207dc5E9423fB15f89ae6031A05fd92',
  transfer: '0x0CFC1a4a90741aB242cAfaCD798b409E12e68926',
  matching: '0x3cc154e220c2197c5337b7Bd13363DD127Bc0C6E',
  trade: '0x87F2863866D85E3192a35A73b388BD625D83f2be',
  liquidate: '0x3e2a570B915fEDAFf6176A261d105A4A68a0EA8D',
  rfq: '0x4E4DD8Be1e461913D9A5DBC4B830e67a8694ebCa',
  portfolioManager: {
    [MarketId.ETH]: '0xDF448056d7bf3f9Ca13d713114e17f1B7470DeBF',
    [MarketId.BTC]: '0xbaC0328cd4Af53d52F9266Cdbd5bf46720320A20',
  },
  accountEntryPoint: '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789',
  accountFactory: '0x000000893A26168158fbeaDD9335Be5bC96592E2',
  paymaster: '0x5a6499b442711feeA0Aa73C6574042EC5E2e5945', // VerifyingPaymaster with fix token fee
  l1CrossDomainMessengerProxy: '0x28976A1DF6e6689Bfe555780CD46dcFcF5552979',
  subaccountCreator: '0xaA52B531BBB5e9d444Dcb304a442ABDFED27475a',
  collateral: {
    [CollateralId.USDC]: '0x6caf294DaC985ff653d5aE75b4FF8E0A66025928',
    [CollateralId.ETH]: '0x41675b7746AE0E464f2594d258CF399c392A179C',
    [CollateralId.USDT]: '0xB696F009e23B31F6a565E187604e43F5B030b241',
    [CollateralId.BTC]: '0x56543a7A848F630a99EfaFF1177907267afb75d0',
    [CollateralId.WSTETH]: '0x8DCa1bC475fB6008165186061398e318dd3e5f06',
    [CollateralId.WEETH]: '0x063793B0AA25CB7784375E1Fed801505838Ebc44',
    [CollateralId.RSWETH]: '0x6e567dC9f952B361D020A594b88149016D7F3df4',
    [CollateralId.RSETH]: '0x911116D3BC6A16BA7af02cc81490639f2A6e7Da7',
    [CollateralId.DAI]: '0xEaAE25403B28D145f74075a6B4A3844cf8dB5fd2',
    [CollateralId.SDAI]: '0x722fC392cF57f1A941B22238FD60a683C2685Ab9',
    [CollateralId.USDE]: '0x14af1E8d2194c5bCB3405f96dd471676c8Fd0CeA',
    [CollateralId.SUSDE]: '0x5fDB7419E7517085B292741f75F5B14Cd6DcAc47',
    [CollateralId.LBTC]: '0x2b743fec38360310D248ba1870a3C341278Ed41F',
    [CollateralId.EBTC]: '0x8763353C3BF746CC320155FAF15B2129D8362519',
    [CollateralId.CBBTC]: '0xe0Ae57C04a29bcB9083ed021cda4b5E136Fbea9D',

    [CollateralId.OP]: '0xc83C7bb86658A2dc2c4312009C118C1DCA5bA19d',
    [CollateralId.DEUSD]: '0xa2514caeE6CEF443B860470C0280Ea544914D35f',
    [CollateralId.SOLVBTC]: '0xCB2CE8A455B5E1ee88B7f8e71EeB9D0F8Fe0746C',
    [CollateralId.SOLVBTCBBN]: '0x4035479371D195590378Af53C17AD9250376ADF0',
    [CollateralId.DRV]: '0x057fd0dEF0BEf37Bf36Aef9CB550faf88428C5D0',
    [CollateralId.STDRV]: undefined,
  },
  tsaShareHandler: '0x05bf05e4D19DaF3234d3aE287B32212924b551Fe',
  migrationClaim: '0x1cbbC18CB128AA470733eD29938Ab4878B0BEF20',
  rewardsDistributor: '0x791A570F5785FBdb02EA5C7a794c43111ae2f948',
}

export const mainnetLyraContractAddresses: LyraContractAddresses = {
  accountEntryPoint: '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789',
  accountFactory: '0x000000893A26168158fbeaDD9335Be5bC96592E2',
  paymaster: '0xa179c3b32d3eE58353d3F277b32D1e03DD33fFCA',
  matching: '0xeB8d770ec18DB98Db922E9D83260A585b9F0DeAD',
  subaccounts: '0xE7603DF191D699d8BD9891b821347dbAb889E5a5',
  deposit: '0x9B3FE5E5a3bcEa5df4E08c41Ce89C4e3Ff01Ace3',
  withdraw: '0x9d0E8f5b25384C7310CB8C6aE32C8fbeb645d083',
  transfer: '0x01259207A40925b794C8ac320456F7F6c8FE2636',
  standardManager: '0x28c9ddF9A3B29c2E6a561c1BC520954e5A33de5D',
  liquidate: '0x66d23e59DaEEF13904eFA2D4B8658aeD05f59a92',
  rfq: '0x9371352CCef6f5b36EfDFE90942fFE622Ab77F1D',
  portfolioManager: {
    [MarketId.ETH]: '0xe7cD9370CdE6C9b5eAbCe8f86d01822d3de205A0',
    [MarketId.BTC]: '0x45DA02B9cCF384d7DbDD7b2b13e705BADB43Db0D',
  },
  trade: '0xB8D20c2B7a1Ad2EE33Bc50eF10876eD3035b5e7b',
  l1CrossDomainMessengerProxy: '0x5456f02c08e9A018E42C39b351328E5AA864174A',
  subaccountCreator: '0xa44e56A6F53b2E27278B468783fe9de6b037e147',
  collateral: {
    [CollateralId.USDC]: '0x57B03E14d409ADC7fAb6CFc44b5886CAD2D5f02b',
    [CollateralId.ETH]: '0xE201fCEfD4852f96810C069f66560dc25B2C7A55',
    [CollateralId.BTC]: '0x7da2D398dddDfC946Efd2C758c4688D21887790d',
    [CollateralId.USDT]: '0x5E72430EC945CCc183c34e2860FFC2b5bac712c2',
    [CollateralId.WSTETH]: '0x69c7AADFfD607F74eE610f047f552CaDecabca4E',
    [CollateralId.WEETH]: '0xF30EE744fCfd135A135E6a4e327e01d0f697e6Ec',
    [CollateralId.RSWETH]: '0xef2Fc00B7F7c71c73a68dAD25c9D673b2e1983ba',
    [CollateralId.RSETH]: '0x35fdB6e79c05809ba6Dc3B2EF5FF7D0BB5D75020',
    [CollateralId.DAI]: '0x67bB0B7c87Df9C5C433ac7eCADfa7396A2927fcF',
    [CollateralId.SDAI]: '0x84f25FB708431F2C2B8596CD30f1f1d94D67f095',
    [CollateralId.USDE]: '0x028B9fFA86fc4c366e11AA8b3E71dc0502713ABF',
    [CollateralId.SUSDE]: '0x375804CdcF0D534FDD2657584A7c4Ff5AB14A2bb',
    [CollateralId.LBTC]: '0xeaF03Bb3280C609d35E7F84d24a996c7C0b74F5f',
    [CollateralId.EBTC]: '0x95FE344A0f420A7aC1B1E69CB1474179a40db882',
    [CollateralId.CBBTC]: '0xC1efE4c45B79250A29A5Dc66cCADc60DB0FcEEe8',

    [CollateralId.OP]: '0xDaffF9B244327d09dde1dDFcf9981ef0Df2D1568',
    [CollateralId.DEUSD]: '0xDe48f1bD7B078fB369edC23007ed4cA2c206804e',
    [CollateralId.SOLVBTC]: '0x4fce062aB932bDCD34221bad7570D436013Cb38F',
    [CollateralId.SOLVBTCBBN]: '0x89b9BB59E0A3128c0054f7E088eF249B003fe110',
    [CollateralId.DRV]: '0x1abdb2896e57047fd989af7b3ee6b7e81830f8d2',
    [CollateralId.STDRV]: undefined,
  },
  tsaShareHandler: '0x4B759054CF4DA826e48645EfaDEfE9AfA613FDc5',
  migrationClaim: '0x455Fed604a0d3f6FD2659852C90aB9E6Dd4bbb6E',
  rewardsDistributor: '0x2f8C5a3BBd69443B6e462F563bA0EaB4317F995b',
}

export const lyraContractAddresses = isMainnet
  ? mainnetLyraContractAddresses
  : testnetLyraContractAddresses

export const USDC_DECIMALS = 6
export const ONE_INCH_ORACLE_ETHEREUM_MAINNET_ADDRESS = '0x07D91f5fb9Bf7798734C3f606dB065549F6893bb'
export const USDC_ETHEREUM_MAINNET_ADDRESS = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
export const MAINNET_LYRA_TOKEN_ADDRESS = '0x01ba67aac7f75f647d94220cc98fb30fcc5105bf'
export const MAINNET_STAKED_LYRA_TOKEN_ADDRESS = '0xCb9f85730f57732fc899fb158164b9Ed60c77D49'
export const OPTIMISM_LYRA_TOKEN_ADDRESS = '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb'
export const ARBITRUM_LYRA_TOKEN_ADDRESS = '0x079504b86d38119F859c4194765029F692b7B7aa'
// stkLYRA
export const ETHEREUM_STK_LYRA_TOKEN_ADDRESS = '0xcb9f85730f57732fc899fb158164b9ed60c77d49'
export const OPTIMISM_STK_LYRA_TOKEN_ADDRESS = '0x0F5d45a7023612e9e244fe84FAc5fCf3740d1492'
export const OPTIMISM_OLD_STK_LYRA_TOKEN_ADDRESS = '0xdE48b1B5853cc63B1D05e507414D3E02831722F8'
export const ARBITRUM_STK_LYRA_TOKEN_ADDRESS = '0x5B237ab26CeD47Fb8ED104671819C801Aa5bA45E'

type ExternalContractAddresses = {
  lyraStakingSink: {
    [DepositNetwork.Optimism]: Address
    [DepositNetwork.Arbitrum]: Address
    [DepositNetwork.Ethereum]: Address
  }
  multiDistributor: Address
}
export const externalContractAddresses: ExternalContractAddresses = {
  lyraStakingSink: {
    [DepositNetwork.Optimism]: '0x1cbbC18CB128AA470733eD29938Ab4878B0BEF20',
    [DepositNetwork.Arbitrum]: '0xea8c58aADfAfA579Df6443EDE307199FFfE0320B',
    [DepositNetwork.Ethereum]: '0x7Fa4ee12BCbe3A7E087839b49a6db96570DE4197',
  },
  multiDistributor: '0x3BB38b77a266Fceb4FA5659e0eCb5ecF6AeAC28D',
}
