import tryRequest from './tryRequest'
import {
  PublicGetCurrencyParamsSchema,
  PublicGetCurrencyResponseSchema,
} from './types/public.get_currency'

export default async function fetchCurrency(params: PublicGetCurrencyParamsSchema) {
  return tryRequest<PublicGetCurrencyParamsSchema, PublicGetCurrencyResponseSchema>(
    '/public/get_currency',
    params,
    { useGet: true }
  )
}
