export const SECONDS_IN_MINUTE = 60
export const SECONDS_IN_HOUR = 3600
export const SECONDS_IN_DAY = 86400
export const SECONDS_IN_THREE_DAYS = 259200
export const SECONDS_IN_WEEK = 604800
export const SECONDS_IN_TWO_WEEKS = 604800 * 2
export const SECONDS_IN_MONTH = 2592000
export const SECONDS_IN_THREE_MONTHS = 7776000
export const SECONDS_IN_SIX_MONTHS = 15552000
export const DAYS_IN_YEAR = 365
export const SECONDS_IN_YEAR = 31536000

export enum Period {
  FifteenMinutes = 900,
  OneHour = 3600,
  FourHours = 14400,
  EightHours = 28800,
  OneDay = 86400,
  SevenDays = 604800,
}

export type DefiLlamaBlockResponse = {
  height: number
  timestamp: number
}
