const getDecimalPlaces = (num: number): number => {
  const numStr = num.toString().toLowerCase()
  if (numStr.includes('e')) {
    const [base, exponent] = numStr.split('e')
    const decimalPlacesInBase = (base.split('.')[1] || '').length
    const exponentValue = parseInt(exponent, 10)
    return Math.max(0, decimalPlacesInBase - exponentValue)
  } else {
    return (numStr.split('.')[1] || '').length
  }
}

export const roundDownToStep = (value: number, step: number): number => {
  if (step === 0) {
    return value
  }

  const stepDecimalPlaces = getDecimalPlaces(step)
  const multiplier = Math.pow(10, stepDecimalPlaces)
  const scaledValue = value * multiplier
  const scaledStep = step * multiplier

  let rounded = Math.floor(scaledValue / scaledStep) * scaledStep
  rounded = rounded / multiplier

  return parseFloat(rounded.toFixed(stepDecimalPlaces))
}

export const roundUpToStep = (value: number, step: number): number => {
  if (step === 0) {
    return value
  }

  const stepDecimalPlaces = getDecimalPlaces(step)
  const multiplier = Math.pow(10, stepDecimalPlaces)
  const scaledValue = value * multiplier
  const scaledStep = step * multiplier

  let rounded = Math.ceil(scaledValue / scaledStep) * scaledStep
  rounded = rounded / multiplier

  return parseFloat(rounded.toFixed(stepDecimalPlaces))
}

export function countDecimals(num: number): number {
  let numStr = num.toString()

  if (numStr.toLowerCase().includes('e')) {
    const fixedStr = num.toFixed(20)
    numStr = fixedStr.replace(/0+$/, '')
  }

  const index = numStr.indexOf('.')
  if (index === -1) return 0
  return numStr.length - index - 1
}
