import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import formatDate from '@lyra/core/utils/formatDate'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatUSD from '@lyra/core/utils/formatUSD'
import { PageId } from '@lyra/web/constants/pages'
import { getPagePath } from '@lyra/web/utils/pages'

import { Board, Instrument, InstrumentType, Strike, Ticker } from '../constants/instruments'
import { DEFAULT_MARKET_ID, MarketId } from '../constants/markets'
import { ONLY_SHOW_EXPIRIES } from '../constants/markets'
import { OrderQuoteParams, PERPS_LEVERAGE_STEP_SIZE } from '../constants/order'
import { CollateralId, CurrencyId } from '../constants/tokens'
import { getCollateralId, getMarketId } from './markets'
import { countDecimals } from './number'
import { getDefaultOrderQuoteParams } from './order'
import { getUtcNowSecs } from './time'
import { coerce } from './types'

export type ParsedOption = {
  type: InstrumentType.Options
  marketId: MarketId
  expiry: Date
  isCall: boolean
  strikePrice: number
}
export type ParsedPerp = { type: InstrumentType.Perps; marketId: MarketId }

export type ParsedSpot = { type: InstrumentType.Spot; marketId: CollateralId }

export const isInstrumentActive = (
  instrument: Instrument | Ticker,
  utcSecondsNow: number
): boolean => {
  const isInstrumentActive =
    instrument.is_active &&
    utcSecondsNow >= instrument.scheduled_activation &&
    utcSecondsNow <= instrument.scheduled_deactivation

  const parsedInstrument = parseInstrumentName(instrument.instrument_name)
  if (!parsedInstrument) {
    // invalid instrument name
    return false
  }

  let showInstrument = true
  if (parsedInstrument.type === InstrumentType.Options) {
    if (ONLY_SHOW_EXPIRIES[parsedInstrument.marketId].length > 0) {
      // filter out expiries
      const onlyShowExpiries = ONLY_SHOW_EXPIRIES[parsedInstrument.marketId]
      showInstrument = !!onlyShowExpiries.find(
        (expiry) => formatExpiryCode(parsedInstrument.expiry) === expiry
      )
    }
  }

  return isInstrumentActive && showInstrument
}

export const parseInstrumentName = (
  instrumentName: string
): ParsedOption | ParsedPerp | ParsedSpot | undefined => {
  const option = parseOptionFromInstrumentName(instrumentName)
  const perp = parsePerpFromInstrumentName(instrumentName)
  const spot = parseSpotFromInstrumentName(instrumentName)
  return option ?? perp ?? spot
}

export const formatExpiryFromOptionInstrumentName = (
  instrumentName: string
): string | undefined => {
  const parsedOptionInstrument = parseOptionFromInstrumentName(instrumentName)
  if (!parsedOptionInstrument) {
    return undefined
  }

  const dayOfMonth = parsedOptionInstrument.expiry.getUTCDate()
  const shortMonth = parsedOptionInstrument.expiry.toLocaleString('default', { month: 'short' })
  return `${shortMonth} ${dayOfMonth} Exp`
}

export const parseExpiryCode = (expiryStr: string): Date | undefined => {
  // Parse the expiry into a Date object
  if (!expiryStr.match(/^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/)) {
    return undefined
  }
  const year = expiryStr.substring(0, 4)
  const month = expiryStr.substring(4, 6)
  const day = expiryStr.substring(6, 8)
  const expiry = new Date(`${year}-${month}-${day}`)
  // Preset to 8am on day of expiry
  expiry.setUTCHours(8)
  return expiry
}

export const getNextWeeklyExpiryUtc = (): Date => {
  const now = new Date()

  // Get the current day of the week (0 = Sunday, 6 = Saturday)
  const currentDay = now.getUTCDay()

  // Calculate how many days until next Friday (5 = Friday)
  const daysUntilFriday = (5 - currentDay + 7) % 7 || 7 // ensures we always move forward to the next Friday

  // Set the date to the next Friday
  const nextFriday = new Date(now)
  nextFriday.setUTCDate(now.getUTCDate() + daysUntilFriday)

  // Set the time to 8:00 AM UTC
  nextFriday.setUTCHours(8)

  return nextFriday
}

export const formatExpiryCode = (expiry: Date): string => {
  const year = expiry.getUTCFullYear() // Gets the UTC year
  const month = expiry.getUTCMonth() + 1 // Gets the UTC month (0-11), so add 1
  const day = expiry.getUTCDate() // Gets the UTC day of the month (1-31)

  // Pad the month and day with a leading zero if they are less than 10
  const monthStr = month < 10 ? `0${month}` : `${month}`
  const dayStr = day < 10 ? `0${day}` : `${day}`

  return `${year}${monthStr}${dayStr}`
}

export const parseOptionFromInstrumentName = (instrumentName: string): ParsedOption | undefined => {
  const regexPattern = /^([A-Z]+)-(\d{8})-(\d+|\d+_\d+)-(C|P)$/
  const match = instrumentName.match(regexPattern)

  if (match) {
    const [, ticker, expiryStr, strike, type] = match

    const marketId = getMarketId(ticker)
    if (!marketId) {
      console.warn(`Market ID not found: ${instrumentName}`)
      return undefined
    }

    // Parse the expiry into a Date object
    const expiry = parseExpiryCode(expiryStr)
    if (!expiry) {
      console.warn('Invalid expiry')
      return undefined
    }

    // Parse the strike price into a number
    const strikePrice = Number(strike.replace('_', '.'))

    // Determine if the type is a call
    const isCall = type === 'C'

    return { type: InstrumentType.Options, marketId, expiry, strikePrice, isCall }
  } else {
    return undefined
  }
}

export const parsePerpFromInstrumentName = (instrumentName: string): ParsedPerp | undefined => {
  const regexPattern = /^([A-Z]+)-PERP$/
  const match = instrumentName.match(regexPattern)

  if (match) {
    const [, ticker] = match
    const marketId = getMarketId(ticker)
    if (!marketId) {
      console.warn(`Market ID not found: ${instrumentName}`)
      return undefined
    }

    return { type: InstrumentType.Perps, marketId }
  } else {
    return undefined
  }
}

export const parseMarketFromInstrumentName = (instrumentName: string): CurrencyId | undefined => {
  return (
    parsePerpFromInstrumentName(instrumentName)?.marketId ??
    parseOptionFromInstrumentName(instrumentName)?.marketId ??
    parseSpotFromInstrumentName(instrumentName)?.marketId
  )
}

export const parseSpotFromInstrumentName = (instrumentName: string): ParsedSpot | undefined => {
  const regexPattern = /^([A-Z]+)-USDC$/
  const match = instrumentName.match(regexPattern)

  if (match) {
    const [, ticker] = match
    const collateralId = getCollateralId(ticker)

    if (!collateralId) {
      console.warn(`Market ID not found for instrument name: ${instrumentName}`)
      return undefined
    }

    return { type: InstrumentType.Spot, marketId: collateralId }
  }

  return undefined
}

type FormatInstrumentOptions = {
  showExpiry?: boolean
}

export const formatInstrument = (instrumentName: string, options?: FormatInstrumentOptions) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  const perp = parsePerpFromInstrumentName(instrumentName)
  const spot = parseSpotFromInstrumentName(instrumentName)

  if (option) {
    return formatOption(instrumentName, options)
  }
  if (perp) {
    return formatPerp(instrumentName)
  }
  if (spot) {
    return formatSpot(instrumentName)
  }
  console.warn('failed to formatInstrument, returning empty string', instrumentName)
  return ''
}

export const formatInstrumentWithDirection = (
  instrumentName: string,
  isBuy: boolean,
  options?: FormatInstrumentOptions
) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  const perp = parsePerpFromInstrumentName(instrumentName)
  const spot = parseSpotFromInstrumentName(instrumentName)

  if (option) {
    return formatOption(instrumentName, options)
  }
  if (perp) {
    return `${isBuy ? 'Long' : 'Short'} ${perp.marketId}`
  }
  if (spot) {
    return `${isBuy ? 'Buy' : 'Sell'} ${spot.marketId}`
  }
  console.warn('failed to formatInstrumentWithDirection, returning empty string', instrumentName)
  return ''
}

// e.g. ETH $1500 Put
// e.g. ETH $1500 Put, Nov 10 Exp
export const formatOption = (instrumentName: string, options?: FormatInstrumentOptions) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  if (!option) {
    console.warn('failed to formatOption, returning empty string', instrumentName)
    return ''
  }
  const expiryStr = formatOptionExpiry(instrumentName)

  return `${option.marketId} ${formatUSD(option.strikePrice, {
    dps: countDecimals(option.strikePrice),
    showCommas: false,
  })} ${option.isCall ? 'Call' : 'Put'}${options?.showExpiry ? `, ${expiryStr}` : ''}`
}

// e.g. Nov 10 Exp
export const formatOptionExpiry = (instrumentName: string) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  if (!option) {
    console.warn('failed to formatOptionExpiry, returning empty string', instrumentName)
    return ''
  }
  return `${formatDate(option.expiry.getTime(), { skipYear: true })} Exp`
}

export const formatInstrumentExpiry = (instrumentName: string) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  const perp = parsePerpFromInstrumentName(instrumentName)
  if (option) {
    return formatOptionExpiry(instrumentName)
  }
  if (perp) {
    return 'Perpetual'
  }
  console.warn('failed to formatInstrumentExpiry, returning empty string', instrumentName)
  return ''
}

export const formatPerp = (instrumentName: string) => {
  const perp = parsePerpFromInstrumentName(instrumentName)
  if (!perp) {
    console.warn('failed to formatPerp, returning empty string', instrumentName)
    return ''
  }
  return `${perp.marketId} Perp`
}

export const formatSpot = (instrumentName: string) => {
  const spot = parseSpotFromInstrumentName(instrumentName)
  if (!spot) {
    console.warn('failed to formatSpot, returning empty string', instrumentName)
    return ''
  }
  return `${spot.marketId}`
}

export const getPerpInstrumentName = (marketId: MarketId) => {
  return `${marketId.toUpperCase()}-PERP`
}

export const getSpotInstrumentName = (collateralId: CollateralId) => {
  return `${collateralId.toUpperCase()}-USDC`
}

// e.g. 1.23x
export const formatPerpLeverage = (leverage: number) =>
  `${formatNumber(leverage, { dps: countDecimals(PERPS_LEVERAGE_STEP_SIZE) })}x`

export const formatInstrumentSize = (instrumentName: string, size: number) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  const perp = parsePerpFromInstrumentName(instrumentName)
  const spot = parseSpotFromInstrumentName(instrumentName)
  if (option) {
    return formatOptionSize(instrumentName, size)
  }
  if (perp) {
    return formatPerpSize(instrumentName, size)
  }
  if (spot) {
    return formatSpotWithSize(instrumentName, size)
  }
  console.warn('failed to formatInstrumentSize, returning empty string', instrumentName)
  return ''
}

// e.g. 5.0 Calls
export const formatOptionSize = (instrumentName: string, size: number) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  if (!option) {
    console.warn('failed to formatOptionSize, returning empty string', instrumentName)
    return ''
  }
  const sizeStr = formatNumber(size, {
    minDps: 1,
  })
  return `${sizeStr} ${option.isCall ? 'Call' : 'Put'}${size !== 1 ? 's' : ''}`
}

// e.g. 5.0 ETH
export const formatPerpSize = (instrumentName: string, size: number) => {
  const perp = parsePerpFromInstrumentName(instrumentName)
  if (!perp) {
    console.warn('failed to formatPerpSize, returning empty string', instrumentName)
    return ''
  }
  const sizeStr = formatNumber(size, {
    minDps: 1,
  })
  return `${sizeStr} ${perp.marketId}`
}

export const formatInstrumentWithSize = (
  instrumentName: string,
  size: number,
  options?: FormatInstrumentOptions
) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  const perp = parsePerpFromInstrumentName(instrumentName)
  const spot = parseSpotFromInstrumentName(instrumentName)
  if (option) {
    return formatOptionWithSize(instrumentName, size, options)
  }
  if (perp) {
    return formatPerpWithSize(instrumentName, size)
  }
  if (spot) {
    return formatSpotWithSize(instrumentName, size)
  }
  console.warn('failed to formatInstrumentWithSize, returning empty string', instrumentName)
  return ''
}

// e.g. 5.0 ETH $2000 Calls
export const formatOptionWithSize = (
  instrumentName: string,
  size: number,
  options?: FormatInstrumentOptions
) => {
  const option = parseOptionFromInstrumentName(instrumentName)
  if (!option) {
    console.warn('failed to formatOptionWithSize, returning empty string', instrumentName)
    return ''
  }

  const sizeStr = formatNumber(size, {
    minDps: 1,
  })

  const expiryStr = formatOptionExpiry(instrumentName)

  return `${sizeStr} ${option.marketId} ${formatUSD(option.strikePrice, {
    dps: 0,
    showCommas: false,
  })} ${option.isCall ? 'Call' : 'Put'}${size !== 1 ? 's' : ''}${
    options?.showExpiry ? `, ${expiryStr}` : ''
  }`
}

// e.g. 5.0 ETH Perps
export const formatPerpWithSize = (instrumentName: string, size: number) => {
  const perp = parsePerpFromInstrumentName(instrumentName)
  if (!perp) {
    console.warn('failed to formatPerpWithSize, returning empty string', instrumentName)
    return ''
  }

  const sizeStr = formatNumber(size, {
    minDps: 1,
  })

  return `${sizeStr} ${perp.marketId} ${size !== 1 ? 'Perps' : 'Perp'}`
}

// e.g. 1.0 ETH Spot // 5.0 ETH Spot
export const formatSpotWithSize = (instrumentName: string, size: number) => {
  const spot = parseSpotFromInstrumentName(instrumentName)
  if (!spot) {
    console.warn('failed to formatSpotWithSize, returning empty string', instrumentName)
    return ''
  }
  const sizeStr = formatNumber(size, {
    minDps: 1,
  })

  return `${sizeStr} ${spot.marketId}`
}

export const getUniqueExpiries = (instruments: Instrument[]): Date[] => {
  return Object.values(
    instruments.reduce(
      (expiries, instrument) => {
        const expiry = instrument.option_details?.expiry
        if (!expiry) {
          return expiries
        }

        if (expiries[expiry]) {
          return expiries
        }
        // Use parsed øption expiry for consistent timing with position/order history
        const parsedOption = parseOptionFromInstrumentName(instrument.instrument_name)
        if (!parsedOption) {
          console.warn(`Trying to parse invalid option instrument name`, instrument.instrument_name)
          return expiries
        }
        return {
          ...expiries,
          [expiry]: parsedOption,
        }
      },
      {} as Record<number, ParsedOption>
    )
  )
    .map((instrument) => instrument.expiry)
    .sort((a, b) => a.getTime() - b.getTime())
}

export function getBoardFromTickers(expiry: Date, tickers: Ticker[], utcNowSeconds: number): Board {
  const tickersByExpiry = tickers.filter((ticker) => {
    if (!isInstrumentActive(ticker, utcNowSeconds)) {
      return false
    }
    const option = parseOptionFromInstrumentName(ticker.instrument_name)
    if (!option) {
      return false
    }
    return option.expiry.getTime() === expiry.getTime()
  })

  const board: Board = { expiry, strikes: {} }

  tickersByExpiry.forEach((ticker) => {
    const strikePrice = +ticker.option_details.strike
    const strike: Strike = board.strikes[strikePrice] ?? {
      call: null,
      put: null,
    }
    if (ticker.option_details.option_type === 'C') {
      strike.call = ticker
    } else {
      strike.put = ticker
    }
    board.strikes[strikePrice] = strike
  })

  return board as Board
}

export const getDefaultExpiry = (expiries: Date[]): Date | undefined => {
  const nowSecs = getUtcNowSecs()
  const tomorrowSecs = nowSecs + SECONDS_IN_DAY * 2 // 48 hour buffer
  const sortedExpiries = [...expiries].sort((a, b) => a.getTime() - b.getTime())
  const nearestWeekly = sortedExpiries.find(
    (expiryMs) => Math.floor(expiryMs.getTime() / 1000) > tomorrowSecs
  )
  if (!nearestWeekly) {
    const nearestExpiry = sortedExpiries[0]
    return nearestExpiry
  } else {
    return nearestWeekly
  }
}

export type InstrumentPathParams = {
  type: InstrumentType
  marketId: CurrencyId
  order?: OrderQuoteParams
}

export const getInstrumentPagePath = (params: InstrumentPathParams | string) => {
  if (typeof params === 'string') {
    const instrument = parseInstrumentName(params)
    if (!instrument) {
      console.warn(`failed to parse instrument link ${params}`)
      return getPagePath({ page: PageId.Options, marketId: DEFAULT_MARKET_ID })
    }
    switch (instrument.type) {
      case InstrumentType.Options:
        return getPagePath({
          page: PageId.Options,
          marketId: instrument.marketId,
          order: getDefaultOrderQuoteParams(params),
          expiry: instrument.expiry, // select option board
        })
      case InstrumentType.Perps:
        return getPagePath({
          page: PageId.Perps,
          marketId: instrument.marketId,
          order: getDefaultOrderQuoteParams(params),
        })
      case InstrumentType.Spot:
        return getPagePath({
          page: PageId.Spot,
          marketId: instrument.marketId,
        })
    }
  } else {
    switch (params.type) {
      case InstrumentType.Options:
        return getPagePath({
          page: PageId.Options,
          marketId: coerce(MarketId, params.marketId),
          order: params.order,
        })
      case InstrumentType.Perps:
        return getPagePath({
          page: PageId.Perps,
          marketId: coerce(MarketId, params.marketId),
          order: params.order,
        })
      case InstrumentType.Spot:
        return getPagePath({
          page: PageId.Spot,
          marketId: coerce(CollateralId, params.marketId),
          order: params.order,
        })
    }
  }
}

// e.g. /options/eth, /options/perps
export const getInstrumentTypePagePath = (instrumentName: string) => {
  const instrument = parseInstrumentName(instrumentName)
  if (!instrument) {
    return undefined
  }
  switch (instrument.type) {
    case InstrumentType.Options:
      return getPagePath({
        page: PageId.Options,
        marketId: instrument.marketId,
      })
    case InstrumentType.Perps:
      return getPagePath({
        page: PageId.Perps,
        marketId: instrument.marketId,
      })
    case InstrumentType.Spot:
      return getPagePath({
        page: PageId.Spot,
        marketId: instrument.marketId,
      })
  }
}

export const getBestOfferPrice = (ticker: Ticker, isBuy: boolean): number | undefined => {
  return isBuy ? getBestAskPrice(ticker) : getBestBidPrice(ticker)
}

export const getBestBidPrice = (ticker: Ticker): number | undefined => {
  return ticker.best_bid_price &&
    +ticker.best_bid_price >= +ticker.min_price &&
    +ticker.best_bid_price <= +ticker.max_price
    ? +ticker.best_bid_price
    : undefined
}

export const getBestAskPrice = (ticker: Ticker): number | undefined => {
  return ticker.best_ask_price &&
    +ticker.best_ask_price >= +ticker.min_price &&
    +ticker.best_ask_price <= +ticker.max_price
    ? +ticker.best_ask_price
    : undefined
}

export const getMinOrMaxPrice = (ticker: Ticker, isBuy: boolean): number => {
  const minPrice = +ticker.min_price
  const maxPrice = +ticker.max_price

  return isBuy ? maxPrice : minPrice
}

export const getBestOrMinOrMaxPrice = (ticker: Ticker, isBuy: boolean): number => {
  const bestPrice = getBestOfferPrice(ticker, isBuy)
  const minOrMaxPrice = getMinOrMaxPrice(ticker, isBuy)
  return bestPrice ? bestPrice : minOrMaxPrice
}
